import Container from 'react-bootstrap/Container';

export default function GuidePage() {
  return (
    <Container className="GuidePage mt-3">
      <iframe
        width="100%"
        title="Hepcat Guide"
        src="https://docs.google.com/document/d/e/2PACX-1vTooKgBrn5p5NGoXrnf6eAoLMRZPJRTOaSRR-fb4lvv1aDAEFoI4u__2MMFoOwQuBf4mg8DUcAtwO5t/pub?embedded=true"
      ></iframe>
    </Container>
  );
}
